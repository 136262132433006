<template>
 <TableStyleWrapper>
  <TableWrapper class="table-responsive">
   <a-table
    :columns="orderTableColumns"
    :dataSource="orderTableData"
    :loading="loadingOrders"
    :pagination="{
          defaultPageSize: 20,
          total: count,
          current: filters.currentPage,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${count} items`,
        }"
    @change="paginationChange"
   />
  </TableWrapper>
 </TableStyleWrapper>
</template>
<script>
import {TableStyleWrapper} from '../../user/style';
import {TableWrapper} from '../../styled';
import DefaultPopup from '../../../components/popup/DefaultPopup';
import {computed, defineComponent} from 'vue';
import {useStore} from "vuex";
import moment from "moment";
import {useRoute, useRouter} from "vue-router";
import {useAbility} from "@casl/vue";

const orderTableColumns = [
 {
  title: '',
  dataIndex: 'signed',
  key: 'signed',
 },
 {
  title: 'Status',
  dataIndex: 'status',
  key: 'status',
 },
 {
  title: 'ID',
  dataIndex: 'id',
  key: 'id',
 },
 {
  title: 'Relatie',
  dataIndex: 'name',
  key: 'name',
 },
 {
  title: 'Aangemaakt door',
  dataIndex: 'userID',
  key: 'userID',
 },
 {
  title: 'Aangemaakt op',
  dataIndex: 'createTimestamp',
  key: 'createTimestamp',
 },
 {
  title: 'Actions',
  dataIndex: 'action',
  key: 'action',
  width: '90px',
 },
];
const OrderListTable = defineComponent({
 name: 'OrderListTable',
 components: {TableStyleWrapper, TableWrapper},
 setup() {
  const {state, dispatch} = useStore();
  const orders = computed(() => state.order.orders);
  const loadingOrders = computed(() => state.order.loadingOrders);
  const filters = computed(() => state.order.filterValues);
  const count = computed(() => state.order.count);
  const router = useRouter();
  const {matched} = useRoute();
  const {path} = matched[1];
  const {can} = useAbility();
  const handleEdit = (id) => {
   router.push(`${path}/${id}`);
  };
  const orderTableData = computed(() =>
   orders.value.map((order) => {
    const {
     id,
     userFullName,
     productName,
     createTimestamp,
     isSigned,
     isSent,
     isCancelled,
     relationFullName,
     statusName,
     subStatusName,
     isExpired,
     statusColor,
     subStatusColor,
     organizationName,
     offerViewed,
     signTimestamp,
     canViewDetail,
     type,
    } = order;
    let signed = {icon: 'clock', color: '#464e51'};

    if (offerViewed && !isSigned) {
     signed = {icon: 'eye', color: '#464e51'};
    }

    if (isSent && isSigned) {
     signed = {icon: 'send', color: '#20C997'};
    }

    if (!isCancelled && isSigned) {
     signed = {icon: 'check', color: '#20C997'};
    }

    if (isCancelled && !isSigned) {
     signed = {icon: 'clock', color: '#E30D0F'};
    }

    if (isExpired) {
     signed = {icon: 'clock', color: '#FA8B0C'};
    }

    if (isCancelled && isSigned) {
     signed = {icon: 'x-circle', color: '#E30D0F'};
    }
    let color = "#272B41";
    if (!canViewDetail) {
     color = "#82898f";
    }

    return {
     key: id + Math.random(),
     id: (<span style={{color: color}}>{id}</span>),
     signed: (
      <span>
                  {isSigned ? (
                   <DefaultPopup content={moment(signTimestamp).format('DD-MM-YYYY HH:mm:ss')} placement="top"
                                 action="hover" className="sDash-order">
                    <sdFeatherIcons type={signed.icon} style={{color: signed.color}} size="16"/>
                   </DefaultPopup>
                  ) : (
                   <sdFeatherIcons type={signed.icon} style={{color: signed.color}} size="16"/>
                  )}
                </span>
     ),
     status: (
      <span className="status-text" style={{
       color: 'white',
       backgroundColor: subStatusColor ? `${subStatusColor}` : `${statusColor}`
      }}>{subStatusName ? subStatusName : statusName}</span>
     ),
     name: (
      <div style={{color: color}}><strong>{relationFullName}</strong><br/><small>
       {type === 'SOLARCONFIRM' ? (
        'Bespaarplan'
       ) : (productName)}
      </small></div>
     ),
     userID: <span style={{color: color}}><strong>{userFullName}</strong><br/><small>{organizationName}</small></span>,
     createTimestamp: <span style={{color: color}}>{moment(createTimestamp).format('DD-MM-YYYY HH:mm:ss')}</span>,
     action: (
      <div className="table-actions">
       {can('view', 'order') && canViewDetail ? (
        <sdButton onClick={() => handleEdit(id)} className="btn-icon" to="#" type="default"
                  shape="circle">
         <sdFeatherIcons type="edit" size="16"/>
        </sdButton>
       ) : ('')}
       {can('view', 'order') && !canViewDetail ? (
        <a-tooltip key="tag" title="Niet toegestaan">
         <sdButton className="btn-icon" to="#" type="default" shape="circle" style={{color: color}}>
          <sdFeatherIcons type="edit" size="16"/>
         </sdButton>
        </a-tooltip>
       ) : ('')}
      </div>
     ),
    };
   }),
  );

  const rowSelection = {
   getCheckboxProps: (record) => ({
    disabled: record.name === 'Disabled order', // Column configuration not to be checked
    name: record.name,
   }),
  };

  const paginationChange = async (event) => {
   await dispatch('setCurrentPageOrder', event.current);
   await dispatch('getOrders');
  }

  return {
   orderTableColumns,
   orderTableData,
   rowSelection,
   loadingOrders,
   filters,
   count,
   state,
   paginationChange
  };
 },
});

export default OrderListTable;
</script>

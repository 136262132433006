<template>
 <CardToolbox>
  <sdPageHeader title="Orders">
   <template #subTitle>
    <span class="title-counter">{{ count }} order{{ count > 0 && count < 2 ? '' : 's' }}</span>
    <AutoCompleteStyled2 style="width:100%">
     <a-input v-model:value="filters.q" placeholder="Zoeken op naam, adres, email..." type="text">
      <template #suffix>
       <sdFeatherIcons type="search"/>
      </template>
     </a-input>
    </AutoCompleteStyled2>
   </template>
  </sdPageHeader>
 </CardToolbox>
 <Main>
  <a-row :gutter="15" class="mb-25">
   <a-col :md="24">
    <a-collapse v-model:activeKey="activeKey" :expand-icon-position="expandIconPosition">
     <a-collapse-panel key="1" header="Filters">
      <template #extra>
       <a-badge v-if="hasFilters" class="mr-2" dot style="margin-left:0;line-height: 24px">
        <sdFeatherIcons size="16" type="filter"/>
       </a-badge>
      </template>
      <a-form :model="filters" class="filters" layout="vertical" @finish="applyFilters">
       <a-row :gutter="16">
        <a-col :md="6" :xs="24" class="mb-25">
         <a-form-item label="Organisatie" name="organizationID" style="margin-bottom: 5px">
          <a-select v-model:value="filters.organizationID" :allowClear="true" :filter-option="false"
                    :showSearch="false" class="sDash_fullwidth-select" maxTagCount="responsive" mode="multiple"
                    size="large">
           <a-select-option v-for="(item,index) in orderOrganization" :key="index" :value="item.key"
                            name="organizationID">
            {{ item.value }}
           </a-select-option>
          </a-select>
         </a-form-item>
        </a-col>
        <a-col :md="6" :xs="24" class="mb-25">
         <a-form-item label="Gebruiker" name="userID" style="margin-bottom: 5px">
          <a-select v-model:value="filters.userID" :allowClear="true" :filter-option="false" :showSearch="true"
                    class="sDash_fullwidth-select"
                    maxTagCount="responsive" mode="multiple" placeholder="Zoeken..." size="large"
                    @search="fetchUserFilters">
           <a-select-option v-for="(item,index) in userFilters" :key="index" :value="item.key"
                            name="userID">
            {{ item.value }}
           </a-select-option>
          </a-select>
         </a-form-item>
        </a-col>
        <a-col :md="6" :xs="24">
         <a-form-item label="Status">
          <a-tree-select
           v-model:value="filters.status"
           :show-checked-strategy="SHOW_PARENT"
           :style="{ width: '100%' }"
           :tree-data="statusFilters"
           allow-clear
           class="ant-tree-select"
           maxTagCount="responsive"
           search-placeholder="Maak een keuze"
           style="min-height: 48px;"
           tree-checkable
          />
         </a-form-item>
        </a-col>
        <a-col :md="6" :xs="24">
         <a-form-item label="Product">
          <a-tree-select
           v-model:value="filters.product"
           :show-checked-strategy="SHOW_PARENT"
           :style="{ width: '100%' }"
           :tree-data="productFilters"
           allow-clear
           class="ant-tree-select"
           search-placeholder="Maak een keuze"
           tree-checkable
          />
         </a-form-item>
        </a-col>
        <a-col :md="6" :xs="24">
         <a-form-item label="Team" name="teamID" style="margin-bottom: 10px">
          <a-select v-model:value="filters.teamID" :allowClear="true" :filter-option="false" :showSearch="false"
                    class="sDash_fullwidth-select" maxTagCount="responsive" mode="multiple" name="roleID"
                    size="large">
           <a-select-option v-for="(item,index) in teamFilters" :key="index" :value="item.key">
            {{ item.value }}
           </a-select-option>
          </a-select>
         </a-form-item>
        </a-col>
        <a-col :md="6" :xs="24">
         <a-form-item label="Klanttype" name="productCustomerType" style="margin-bottom: 10px">
          <a-select v-model:value="filters.productCustomerType" :allowClear="true" :filter-option="false"
                    :showSearch="false"
                    class="sDash_fullwidth-select" maxTagCount="responsive" mode="multiple" name="roleID"
                    size="large">
           <a-select-option v-for="(item,index) in productCustomerType" :key="index" :value="item.value">
            {{ item.key }}
           </a-select-option>
          </a-select>
         </a-form-item>
        </a-col>
        <a-col :md="6" :xs="24">
         <a-form-item label="Geannuleerd" name="isCancelled" style="margin-bottom: 10px">
          <a-select v-model:value="filters.isCancelled" :allowClear="true" class="sDash_fullwidth-select"
                    maxTagCount="responsive"
                    name="roleID" size="large">
           <a-select-option v-for="(item,index) in defaultOptionsString" :key="index" :value="item.value">
            {{ item.key }}
           </a-select-option>
          </a-select>
         </a-form-item>
        </a-col>
        <a-col :md="6" :xs="24">
         <a-form-item label="Ondertekend" name="isSigned" style="margin-bottom: 10px">
          <a-select v-model:value="filters.isSigned" :allowClear="true" class="sDash_fullwidth-select" name="roleID"
                    size="large">
           <a-select-option v-for="(item,index) in defaultOptionsString" :key="index" :value="item.value">
            {{ item.key }}
           </a-select-option>
          </a-select>
         </a-form-item>
        </a-col>
        <a-col :md="6" :xs="24">
         <a-form-item label="Verzonden" name="isSent" style="margin-bottom: 10px">
          <a-select v-model:value="filters.isSent" :allowClear="true" class="sDash_fullwidth-select" name="roleID"
                    size="large">
           <a-select-option v-for="(item,index) in defaultOptionsString" :key="index" :value="item.value">
            {{ item.key }}
           </a-select-option>
          </a-select>
         </a-form-item>
        </a-col>
        <a-col :md="6" :xs="24">
         <a-form-item label="Datum filteren op" name="dateFilterType" style="margin-bottom: 10px">
          <a-select v-model:value="filters.dateFilterType" :allowClear="true" class="sDash_fullwidth-select"
                    name="roleID"
                    size="large">
           <a-select-option v-for="(item,index) in dateFilterType" :key="index" :value="item.value">
            {{ item.key }}
           </a-select-option>
          </a-select>
         </a-form-item>
        </a-col>
        <a-col :md="6" :xs="24">
         <a-form-item label="Datum van" name="dateFrom">
          <DatePickerWrapper>
           <a-date-picker v-model:value="filters.dateFrom" :format="dateFormat" class="w-100"/>
          </DatePickerWrapper>
         </a-form-item>
        </a-col>
        <a-col :md="6" :xs="24">
         <a-form-item label="Datum tot" name="dateTill">
          <DatePickerWrapper>
           <a-date-picker v-model:value="filters.dateTill" :format="dateFormat" class="w-100"/>
          </DatePickerWrapper>
         </a-form-item>
        </a-col>
        <a-col :xs="24" class="mt-5">
         <a-button class="btn-signin  mr-2" html-type="submit" size="large" style="line-height: 1;text-align: center;"
                   type="primary">
          <sdFeatherIcons class="mr-1" size="14" style="line-height: 1;text-align: center" type="filter"/>
          <span>Filter</span>
         </a-button>
         <a-button class="btn-signin" size="large" style="line-height: 1;text-align: center;" type="secondary"
                   @click.prevent="resetFilters">
          <span>Reset</span>
         </a-button>
        </a-col>
       </a-row>
      </a-form>
     </a-collapse-panel>
    </a-collapse>
   </a-col>
  </a-row>
  <a-row :gutter="15">
   <a-col :md="24">
    <OrderTable/>
   </a-col>
  </a-row>
 </Main>
</template>
<script>
import OrderTable from './component/OrderTable';
import {computed, defineComponent, onMounted, ref, watch} from 'vue';
import {useStore} from 'vuex';
import {AutoCompleteStyled2, CardToolbox, Main} from '../styled';
import {TreeSelect} from "ant-design-vue";
import {dateFilterType, defaultOptionsString, productCustomerType} from "../../utility/enums";

const OrderList = defineComponent({
 name: 'OrderList',
 components: {Main, CardToolbox, OrderTable, AutoCompleteStyled2},
 data() {
  return {
   dateFormat: 'DD-MM-YYYY',
   showEditDrawer: false,
   SHOW_PARENT: TreeSelect.SHOW_PARENT,
  }
 },
 setup() {
  const {state, dispatch} = useStore();
  const searchData = computed(() => state.headerSearchData);
  const count = computed(() => state.order.count);
  const filters = computed(() => state.order.filters);
  const orderOrganization = computed(() => state.filter.orderOrganization);
  const statusFilters = computed(() => state.filter.orderStatus);
  const productFilters = computed(() => state.filter.productFilters);
  const teamFilters = computed(() => state.filter.orderTeams);
  const userFilters = computed(() => state.filter.userFilters);
  const selectedRowKeys = ref(0);
  const selectedRows = ref(0);
  const search = ref(null);
  const activeKey = ref(['0']);
  const expandIconPosition = ref('left');
  let debounce = null;
  let debounceUserFilter = null;

  const hasFilters = computed(() => {
   return filters.value.isSent || filters.value.isSigned || filters.value.isCancelled || filters.value.productCustomerType?.length > 0
    || filters.value.dateTill || filters.value.dateFrom || filters.value.userID?.length > 0
    || filters.value.teamID?.length > 0 || filters.value.organizationID?.length > 0 || filters.value.roleID?.length > 0
    || filters.value.status?.length > 0 || filters.value.product?.length > 0;
  });

  const applyFilters = async () => {
   const searchValues = JSON.parse(JSON.stringify(filters.value));
   searchValues.q = search.value;
   searchValues.status = searchValues.status ? searchValues.status.join(',') : null;
   searchValues.organizationID = searchValues.organizationID ? searchValues.organizationID.join(',') : null;
   searchValues.product = searchValues.product ? searchValues.product.join(',') : null;
   searchValues.roleID = searchValues.roleID ? searchValues.roleID.join(',') : null;
   searchValues.userID = searchValues.userID ? searchValues.userID.join(',') : null;
   searchValues.teamID = searchValues.teamID ? searchValues.teamID.join(',') : null;
   searchValues.dateFrom = searchValues.dateFrom ? searchValues.dateFrom : null;
   searchValues.dateTill = searchValues.dateTill ? searchValues.dateTill : null;
   searchValues.dateFilterType = searchValues.dateFilterType ? searchValues.dateFilterType : 'CREATETIMESTAMP';
   searchValues.productCustomerType = searchValues.productCustomerType ? searchValues.productCustomerType.join(',') : null;
   searchValues.isCancelled = searchValues.isCancelled ? searchValues.isCancelled : null;
   searchValues.isSigned = searchValues.isSigned ? searchValues.isSigned : null;
   searchValues.isSent = searchValues.isSent ? searchValues.isSent : null;
   await dispatch('setOrderFilters', searchValues);
   await dispatch('getOrders', searchValues);
  };

  const resetFilters = () => {
   filters.value.q = null;
   filters.value.organizationID = [];
   filters.value.roleID = [];
   filters.value.status = [];
   filters.value.product = [];
   filters.value.userID = [];
   filters.value.teamID = [];
   filters.value.dateFilterType = 'CREATETIMESTAMP';
   filters.value.productCustomerType = [];
   filters.value.dateFrom = null;
   filters.value.dateTill = null;
   filters.value.isCancelled = null;
   filters.value.isSigned = null;
   filters.value.isSent = null;
   filters.value.currentPage = 1
   dispatch('setOrderFilters', filters.value);
   dispatch('getOrders', filters.value);
  };

  const fetchUserFilters = (search) => {
   clearTimeout(debounceUserFilter)
   debounceUserFilter = setTimeout(() => {
    dispatch('getUserFilters', {q: search, pageSize: 20});
   }, 300);
  };

  watch(
   () => filters.value.q,
   (query) => {
    clearTimeout(debounce)
    debounce = setTimeout(async () => {
     await dispatch('setOrderFilters', {q: query, currentPage: 1});
     await dispatch('getOrders', filters);
    }, 600);
   }
  );

  onMounted(() => {
   dispatch('getOrderOrganizationFilters');
   dispatch('getOrderTeamFilters', null);
   dispatch('getOrderStatusFilters');
   dispatch('getOrderProductFilters');
   dispatch('getUserFilters', {q: 'a', pageSize: 20});
   dispatch('getOrders');
   search.value = filters.value.q;
  });

  return {
   productCustomerType,
   searchData,
   selectedRowKeys,
   selectedRows,
   search,
   filters,
   hasFilters,
   applyFilters,
   orderOrganization,
   statusFilters,
   resetFilters,
   defaultOptionsString,
   teamFilters,
   dateFilterType,
   activeKey,
   expandIconPosition,
   count,
   productFilters,
   fetchUserFilters,
   userFilters,
  };
 },
});

export default OrderList;
</script>
<style>
.ant-calendar-picker-container {
 z-index: 999999 !important;
}

.ant-select-dropdown {
 z-index: 99999;
}

@media only screen and (min-width: 960px) {
 .ant-popover-inner {
  width: 600px;
 }
}

@media only screen and (min-width: 1440px) {
 .ant-popover-inner {
  width: 700px;
 }
}

@media only screen and (min-width: 2000px) {
 .ant-popover-inner {
  width: 800px;
 }
}

@media only screen and (max-device-width: 480px) {
 .ant-popover-inner {
  width: 400px;
 }
}

@media only screen and (device-width: 768px) {
 .ant-popover-inner {
  width: 600px;
 }
}

.ant-form-vertical.filters .ant-form-item-label {
 margin-left: 10px;
 margin-bottom: -20px;
 z-index: 9999;
}

.ant-form-vertical.filters .ant-form-item-label > label {
 background: #ffffff;
 padding: 0 5px;
}

.ant-collapse {
 border: 0 !important;
}

.ant-collapse > .ant-collapse-item:last-child, .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
 min-height: 48px;
 /*height: 48px;*/
 margin-bottom: -1px;
 color: #272B41;
 font-size: 16px;
 background: #ffffff;
 border-bottom: 1px solid #f0f0f0;
 /*border-radius: 10px 10px 0 0;*/
 line-height: 24px;
}

.ant-collapse > .ant-collapse-item .ant-collapse-header .ant-collapse-arrow {
 font-size: 12px !important;
 color: #272B41 !important;
}

.ant-select-selector {
 min-height: 52px;
}

.ant-collapse-extra {
 line-height: 34px;
}
</style>
